import { i18n } from "common/i18n";
import { Option } from "common/models/option";

export const ROOT_ELEMENT_SELECTOR: string = "main-app";
export const JS_CONF = JSAPP_CONF;
export const STATIC_URL = JSAPP_CONF?.static;

export enum Role {
    Participant = "participant",
    Coach = "coach",
    ProgramManager = "program_manager",
    Coachee = "coachee"
}

export const userRoles = {
    participant: [String(Role.Participant)],
    coach: [String(Role.Coach), String(Role.ProgramManager)]
};

export const allUserRoles = [...userRoles.participant, ...userRoles.coach];

export enum ServerStateKeys {
    Coach = "coach",
    Phases = "phases",
    ModulesByCategory = "modulesByCategory",
    Participants = "participants",
    Resources = "resources",
    ChapterLearn = "chapterLearn",
    ParticipantData = "participantData",
    User = "user",
    ParticipantProgress = "participantProgress",
    ReflectionQuestions = "reflectionsQuestions",
    DiagnosticResponses = "diagnosticResponses",
    WaitingListText = "waitingListText",
    OnboardingResults = "onboardingResults",
    CoachingSession = "coachingSession",
    CoachSessions = "coachSessions",
    AvailableGuestSessions = "availableGuestSessions",
    AssignedModules = "assignedModules",
    GroupInfo = "groupInfo",
    ProductType = "productType",
    onboardingSteps = "onboardingSteps",
    Offboarding = "offboarding",
    CoachRating = "coachRating",
    Nps = "Nps"
}

export enum Status {
    Completed = "complete",
    Queued = "queued",
    Open = "open",
    InProgress = "in_progress",
    Unassigned = "unassigned"
}

export enum ComponentType {
    "Content" = "content",
    "Journaling" = "journaling",
    "Media: Video Vimeo" = "video_vimeo",
    "Question: Free Response" = "question",
    "Question: Likert Group" = "question_group",
    "Track Practice" = "track_practice"
}

export enum ReflectionComponentType {
    FREE_RESPONSE = "question",
    LIKERT_GROUP = "question_variants",
    COLORED_LIKERT_GROUP = "question_variants_color"
}

export enum Reflection {
    OBJECTIVE = "reflection_objective",
    FINAL = "reflection_final"
}

export const defaultAllowedStyles = {
    "*": {
        color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
        "text-align": [/^left$/, /^right$/, /^center$/],
        "font-size": [/^\d+(?:px|em|%)$/]
    }
};

export enum ParticipantUrls {
    CHAPTER_LEARN_INIT = "/chapter/learn/:moduleId/:phaseIndex/:chapterIndex/:id",
    CHAPTER_LEARN_COMPONENT = "/chapter/learn/:moduleId/:phaseIndex/:chapterIndex/:id/:order/:type",
    CHAPTER_LEARN_WELCOME = "/chapter/learn/welcome/:moduleId/:phaseIndex/:chapterIndex/:id",
    DIAGNOSTIC = "/diagnostic-summary",
    WAITING_LIST = "/waiting-list",
    SESSION_LIST = "/session-list",
    SESSION_SWAPPING = "/session-swapping/:sessionId",
    PARTICIPANT_REDIRECT_URL_PAGE = "/post-journey-reflection",
    END_OF_JOURNEY_PAGE = "/end-journey",
    OFFBOARDING = "/offboarding",
    DASHBOARD = "/dashboard",
    PROFILE = "/profile",
    COACHING_PLAN = "/coaching-plan",
    COACH_RATING = "/session-feedback/:sessionId",
    DASHBOARD_MODULE = "/dashboard/:moduleId",
    WAITING_LIST_SSO = "/waiting-list/unrecognized-sso/:orgSlug",
    COACHEES = "/simulab-coachees",
    SIMULAB_CHAT = "/simulab/:conversationId",
    ASSIGN_MODULE = "/assign-module"
}

export enum CoachUrls {
    PARTICIPANTS_LIST = "/participants",
    SESSIONS_LIST = "/sessions",
    GROUP_LIST = "/groups",
    PARTICIPANT_DETAILS = "/participant/:id",
    PARTICIPANT_WORKS = "/participant-works/:id",
    GROUP_INFO = "/group-info/:id",
    CALENDAR_AVAILABILITY = "/calendar-settings/availability/",
    CALENDAR_LIST = "/calendar/list/",
    INDIVIDUAL_SESSIONS = "/session-participant/:id",
    PARTICIPANT_COACHING_PLAN = "/coaching-plan-participant/:participantId"
}

export enum AuthUrls {
    LOGIN = "/login",
    SET_PASSWORD = "/register/:key/",
    RESET_PASSWORD = "/reset-password/:key/"
}

export enum CoachDashboardTab {
    SESSIONS = "sessions",
    PARTICIPANTS = "participants",
    GROUP = "groups"
}

export const MAX_WIDTH_MOBILE = 767;
export const MAX_WIDTH_SMALL_MOBILE = 576;

export const MAX_WIDTH_MOBILE_MEDIA = `(max-width: ${MAX_WIDTH_MOBILE}px)`;
export const MAX_WIDTH_SMALL_MOBILE_MEDIA = `(max-width: ${MAX_WIDTH_SMALL_MOBILE}px)`;

export enum SortKeysSession {
    Desc = "start",
    Asc = "-start"
}

export const DATE_FORMAT = {
    SHORT_MONTH_DOT_DAY: "LLL. dd",
    SHORT_MONTH_DAY_NOT_PADDED: "LLL d",
    WEEKDAYDD: "EEE dd",
    SHORT_WEEK_SHORT_MONTH_DAY: "EEE, MMM d",
    HOUR_MINUTES_MERIDIEM: "h:mm a",
    TIMEZONE_ABBREVIATED: "ZZZZ",
    MONTH_DAY_YEAR: "LLL. d, yyyy",
    SHORT_DAY_SHORT_MONTH_NUMBER: "ccc, LLL d",
    SHORT_DAY_SHORT_MONTH: "MMM, d"
};

export const CARE_TEAM_EMAIL = "mailto:care@wisermanagers.com";

export enum SessionType {
    PAST = "past",
    UPCOMING = "upcoming"
}

export const COACHING_SESSION_TYPE_SLUG = {
    INDIVIDUAL_ONBOARDING: "individual_onboarding",
    GROUP_ONBOARDING: "group_onboarding",
    INDIVIDUAL_COACHING: "individual_coaching",
    GROUP_COACHING: "group_coaching",
    INDIVIDUAL_BONUS: "individual_bonus",
    SPOT_COACHING_WORKSHOP: "spot_coaching_workshop",
    INDIVIDUAL_LEADERSHIP_ONBOARDING: "individual_leadership_onboarding",
    GROUP_LEADERSHIP_ONBOARDING: "group_leadership_onboarding",
    INDIVIDUAL_EXECUTIVE_ONBOARDING: "individual_executive_onboarding",
    GROUP_EXECUTIVE_ONBOARDING: "group_executive_onboarding",
    INDIVIDUAL_LEADERSHIP_COACHING: "individual_leadership_coaching",
    GROUP_LEADERSHIP_COACHING: "group_leadership_coaching",
    INDIVIDUAL_EXECUTIVE_COACHING: "individual_executive_coaching",
    GROUP_EXECUTIVE_COACHING: "group_executive_coaching"
} as const;

export const BONUS_SESSION_RANK = -1;

export const ONE_MINUTE = 60 * 1000;
export const SESSION_CHECK_TIME = 10 * 1000;
export const SESSION_AVAILABLE_TIME = 15;
export const SESSION_REFETCH_DELAY_TIME = 5;
export const DEFAULT_SESSION_DURATION = 90;
export const USER_ACTIVITY_EXPIRE_TIME = 60 * 60 * 1000;
export const USER_ACTIVITY_DEBOUNCE_TIME = 3 * 1000;
export const DEFAULT_TRANSITION_TIME = 300;
export type ModuleStatus = "on_track" | "late" | "complete";

export enum ModuleStatuses {
    ON_TRACK = "on_track",
    LATE = "late",
    COMPLETE = "complete"
}

export type MarkAttendeeType = "default" | "marked" | "unmarked";

export enum CronofyNotificationType {
    ERROR = "error",
    SLOT_SELECTED = "slot_selected",
    NO_SLOTS_FOUND = "no_slots_found"
}

export enum SessionScheduleStatus {
    SCHEDULED = "scheduled",
    NOT_SCHEDULED = "not_scheduled",
    CANCELLED = "cancelled",
    RESCHEDULED = "rescheduled"
}

export enum CoachRatingAskedFrom {
    POST_MODULE_REFLECTION_POPUP = "post-module",
    OFFBOARDING = "offboarding"
}

export type CoachRatingNumber = 1 | 2 | 3 | 4 | 5;

export type CoachRatingSource = CoachRatingAskedFrom.POST_MODULE_REFLECTION_POPUP | CoachRatingAskedFrom.OFFBOARDING;

export const ERROR_TEXT = i18n.t(
    "Uh oh! Something has gone wrong. Our team is looking into it. Please try again or contact support."
);

export const SCHEDULE_STATUS_TEXT = {
    [String(SessionScheduleStatus.NOT_SCHEDULED)]: i18n.t("Not yet scheduled")
};

export const FREE_RESPONSE_TEXT_LENGTH = 1024;

export const COACH_BLOCK_TRANSITION_DELAY = 60;

export const MAIN_PORTAL_ID = "modal-portal";
export const SECONDARY_PORTAL_ID = "secondary-portal";
export const POST_JOURNEY_REDIRECT_URL = "https://wiser.hudsoninstitute.com/post-journey-reflection/";

export enum ProductType {
    SPOT_COACHING = "spot-coaching",
    LEADERSHIP = "leadership-coaching",
    EXECUTIVE = "executive-coaching",
    WISER_DEMO = "wiser-demo",
    WISER = "wiser"
}

export const DEFAULT_LIKERT_COLOR_VAR = "var(--likert-choice-radio-selected-color)";

export const COACHING_GOAL_STATUS = {
    All: "all",
    Active: "active",
    Archived: "archived"
} as const;

export const EXTRA_PARTY_TYPE = {
    Manager: "manager"
} as const;

export type EXTRA_PARTY_TYPES = (typeof EXTRA_PARTY_TYPE)[keyof typeof EXTRA_PARTY_TYPE];

export type CoachingGoalStatus = (typeof COACHING_GOAL_STATUS)[keyof typeof COACHING_GOAL_STATUS];
export const PRODUCT_TYPE_WITH_ENROLLMENTS = [
    ProductType.SPOT_COACHING,
    ProductType.WISER,
    ProductType.WISER_DEMO,
    ProductType.LEADERSHIP,
    ProductType.EXECUTIVE
];

export enum ConfigKeys {
    showDashboard = "showDashboard",
    canApplyModules = "canApplyModules",
    showModuleReflection = "show-post-module-reflection",
    showCoachingPlan = "show-coaching-plan",
    hasEnrolledModules = "hasEnrolledModules"
}

export type ConfigKey = (typeof ConfigKeys)[keyof typeof ConfigKeys];

export const SSO_ORG_EMAILS_REGEX = /@hp\.com$/i;

export const COACHING_PLAN_MENU_ITEMS = [
    new Option({ value: "edit", label: i18n.t("Edit") }),
    new Option({ value: "archive", label: i18n.t("Archive") })
];

export const COLORS = {
    BRAND_COLOR: "#fd4d00",
    BRAND_SECONDARY: "#6f6f6f",
    BRAND_DARK: "#000000",
    BRAND_BG: "#f3f3f3",
    BRAND_ACCENT: "#b0b0b0",
    BRAND_ACCENT_LIGHT: "#e3e3e3",
    BRAND_SECONDARY_LIGHT: "#FFC104"
};

export const COACH_AVAILABILITY_TIME = {
    startTime: "7:00",
    endTime: "19:00"
} as const;

export const ANIMATION_LINKS = {
    CONFETTI: "https://lottie.host/f01266f1-e9ea-48a7-957c-340aeb24009f/lqvQoYWIGB.json",
    SPINNER: "https://lottie.host/039924fb-9a0d-4c7b-a1dd-208991e48611/s9bCTlrwxw.json"
};

export const WISER_COACHING_LINK = "http://www.wisercoaching.com";

export const COACHING_PLAN_ARCHIVE_ITEMS = [new Option({ value: "unarchive", label: i18n.t("Unarchive") })];

const coachRoutePaths = [...Object.values(CoachUrls)];
const participantRoutePath = [...Object.values(ParticipantUrls)];

export const userRoutesPath = {
    [Role.Participant]: participantRoutePath,
    [Role.Coach]: coachRoutePaths,
    [Role.ProgramManager]: coachRoutePaths
};

export const PARTICIPANTS_IN_ROW_MAX_COUNT = 7;
