const isObjectType = (value: unknown) => typeof value === "object";

const isNullOrUndefined = (value: unknown): value is null | undefined => value == null;

const isUndefined = (val: unknown): val is undefined => val === undefined;

const isDateObject = (value: unknown): value is Date => value instanceof Date;

const isObject = <T extends object>(value: unknown): value is T =>
    !isNullOrUndefined(value) && !Array.isArray(value) && isObjectType(value) && !isDateObject(value);

const isEmptyString = (str: string) => {
    if (isNullOrUndefined(str)) {
        return true;
    }

    return str.trim().length === 0;
};

const isEmptyObject = <T extends object>(value: unknown): value is T =>
    !isNullOrUndefined(value) && isObject(value) && Object.keys(value).length === 0;

export { isObject, isObjectType, isNullOrUndefined, isUndefined, isEmptyString, isEmptyObject, isDateObject };
