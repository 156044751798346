import { TranslationObj } from "common/utils/lang";

export interface IOption {
    value: string;
    id?: number;
    key?: any;
    controlled?: boolean;
    slug?: string;
}

type OptionData = {
    value: string | number | null;
    id: number | string;
    label: string | number;
    disabled: boolean;
    name?: TranslationObj;
};

export class Option {
    value: string | null | number;
    id?: string | number;
    disabled?: boolean;
    label?: string | number;
    name?: TranslationObj;

    constructor(data: Partial<OptionData>) {
        this.value = data.value;
        this.id = data.id;
        this.label = data.label || "";
        this.disabled = data.disabled ?? false;
        this.name = data?.name ?? { en: "" };
    }
}
