import Cookies from "js-cookie";

// const setToken = (token: string) => {
//     Cookies.set("token", token);
// };

const getToken = (): string => {
    const token = Cookies.get("token");
    return token || "";
};

const removeToken = () => {
    Cookies.remove("token");
};

export { getToken, removeToken };
