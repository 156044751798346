import { Language } from "main-app/models/state";
import { Settings } from "luxon";

import { isNullOrUndefined } from "./gates";

export type TranslationObj = {
    [key in Language]?: string;
};

export const getUserLanguage = () => {
    const defaultLanguage = JSAPP_CONF?.lang ?? "en";

    if ("navigator" in window && window !== undefined) {
        const langKey = navigator.language.split("-")[0];
        const languages = !isNullOrUndefined(JSAPP_CONF?.available_languages)
            ? Object.keys(JSAPP_CONF?.available_languages)
            : [];
        const language = languages.find(lang => lang === langKey);
        return defaultLanguage !== "en" && JSAPP_CONF?.lang?.length > 0 ? defaultLanguage : language;
    }

    return defaultLanguage;
};

export const withTranslation = <T extends TranslationObj>(obj: T): string => {
    const lang = JSAPP_CONF?.lang;

    return obj?.[lang] ?? obj?.["en"] ?? "";
};

export const setLuxonLocale = () => {
    Settings.defaultLocale = JSAPP_CONF?.lang ?? "en-US";
};
