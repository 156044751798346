import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { JS_CONF } from "main-app/constants";

import { ExtraErrorData as ExtraErrorDataIntegration, CaptureConsole } from "@sentry/integrations";

const serverErrorsRegex = new RegExp(`401 Unauthorized`, "mi");

export const initSentry = () => {
    Sentry.init({
        dsn: JS_CONF.sentry_dsn_js,
        environment: JS_CONF.sentry_env,
        attachStacktrace: true,
        normalizeDepth: 10,
        integrations: [
            new Integrations.BrowserTracing(),
            new ExtraErrorDataIntegration(),
            new CaptureConsole({ levels: ["error"] })
        ],
        ignoreErrors: [serverErrorsRegex]
    });
};
