import axios, { AxiosError } from "axios";
import { getToken } from "./utils/tokens";

const getHeaders = () => {
    let headers: any = { "X-Requested-With": "XMLHttpRequest" };
    const token = getToken();

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    return headers;
};

const http = axios.create({
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
    headers: getHeaders()
});

http.interceptors.response.use(
    (response: any) => {
        if (response.status >= 200 && response.status < 303 && response.headers?.location) {
            location.href = response.headers.location;
        }

        return response;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

http.interceptors.request.use((request: any) => {
    if (request.data && request.data.type && request.data.type === "html") {
        let form = "";

        for (let item of request.data.body) {
            form += `${item.name}=${encodeURIComponent(item.value)}&`;
        }

        request.data = form;
    }

    request.headers["Authorization"] = `Token ${getToken()}`;

    return request;
});

export { http };
